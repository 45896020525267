import React, { useEffect, useState } from 'react';
import Nav from './Nav';

const Home = () => {
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='relative h-screen overflow-hidden'>
     <img 
      src={process.env.PUBLIC_URL + '/assets/img/feur.png'} 
      alt="fusee" 
      className='absolute top-0 left-0 w-full h-full object-cover'
    />
      <Nav />
      <div className="background" />
     
      <div className="w-full h-full relative flex items-center justify-center">
        <div className='flex justify-center w-auto'>
          
         <img 
  src={process.env.PUBLIC_URL + '/assets/img/jetp.gif'} 
  alt="fusee" 
  className='w-4/5 md:w-1/5 lg:w-1/5 absolute top-20 z-0 right-0'
 
  data-aos="fade-up"
/>
        </div>
        
        <div className='flex flex-col items-start justify-center md:h-full p-5 md:p-10'>
        <h1 className='font-bold text-center text-slate-50 text-5xl sm:text-6xl md:text-7xl lg:text-8xl xl:text-9xl 2xl:text-10xl'
    style={{
      fontSize: 'clamp(3rem, 10vw, 12rem)',
      lineHeight: '1.1',
      color: 'white',
      textShadow: `
        -1px -1px 0 #9333ea,  
         1px -1px 0 #9333ea,
        -1px  1px 0 #9333ea,
         1px  1px 0 #9333ea,
        -2px -2px 0 #9333ea,  
         2px -2px 0 #9333ea,
        -2px  2px 0 #9333ea,
         2px  2px 0 #9333ea
      `
    }}>
  POLMOON
</h1>
         
        </div>
      </div>

      {/* Barre de défilement en bas de la page */}
      <div className='absolute bottom-0 left-0 w-full bg-purple-600 text-white overflow-hidden'>
        <div className='ticker-wrap'>
          <div className='ticker'>
            <div className='ticker__item'>Polmoon 🟣 The memecoin that will make Polygon Great Again! 🚀</div>
          </div>
        </div>
      </div>

      {/* Styles pour l'animation de défilement */}
      <style>
        {`
          .ticker-wrap {
            width: 100%;
            overflow: hidden;
            height: 4rem;
            background-color: rgba(0, 0, 0, 0.5);
            padding-left: 100%;
            box-sizing: content-box;
          }
          .ticker {
            display: inline-block;
            height: 4rem;
            line-height: 4rem;  
            white-space: nowrap;
            padding-right: 100%;
            box-sizing: content-box;
            -webkit-animation-iteration-count: infinite; 
                    animation-iteration-count: infinite;
            -webkit-animation-timing-function: linear;
                    animation-timing-function: linear;
            -webkit-animation-name: ticker;
                    animation-name: ticker;
            -webkit-animation-duration: 10s;
                    animation-duration: 10s;
          }
          .ticker__item {
            display: inline-block;
            padding: 0 2rem;
            font-size: 2rem;
          }
          @-webkit-keyframes ticker {
            0% {
              -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
              visibility: visible;
            }
            100% {
              -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
            }
          }
          @keyframes ticker {
            0% {
              -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
              visibility: visible;
            }
            100% {
              -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
            }
          }
        `}
      </style>
    </div>
  );
}

export default Home;