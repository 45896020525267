import React from 'react';

function Nav() { // Pas de fonction pour le bouton
  return (
    <div className="z-40 absolute top-0 left-0 w-screen flex justify-center items-center bg-purple-900">
      <nav className="w-full flex justify-center items-center">
        <ul className="flex justify-center items-center p-4 w-full tracking-wide text-xl bg-transparent">
          <li className="text-white mx-8 my-4 transition duration-300 ease-in-out transform hover:scale-105 hover:text-purple-600">
            <a href="#home">Home</a>
          </li>
          <li className="text-white mx-8 my-4 transition duration-300 ease-in-out transform hover:scale-105 hover:text-purple-600">
            <a href="#about">About</a>
          </li>
          <li className="text-white mx-8 my-4 transition duration-300 ease-in-out transform hover:scale-105 hover:text-purple-600">
            <a href="#tokenomics">Tokenomics</a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Nav;