import React, { useState, useRef, useEffect } from 'react';
// Import Font Awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faForward, faBackward } from '@fortawesome/free-solid-svg-icons';

function Music({ setCurrentView }) {
    const [currentTrack, setCurrentTrack] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [animationStarted, setAnimationStarted] = useState(false);
    const [scrollDuration, setScrollDuration] = useState(120); // Default duration for text scrolling
    const [currentTime, setCurrentTime] = useState(0);
    const audioRef = useRef(null);

    const tracks = [
        { 
            id: 1, 
            title: "Make Polygon great again", 
            artist: "Satoshi", 
            albumArt: "/assets/music/imgMusic/Polmoon1.jpeg", 
            audioSrc: "/assets/music/Polmoon.mp3", 
            delay: 5000, 
            duration: 190, // Duration for text scrolling
            scrollingText: "Listen up, I’m back, this is the time,\nPolygon’s gonna shine, we’re rewriting the climb.\nOther coins? Seriously, they don’t stand a chance,\nWith Polmoon, we’re already leading the dance.\n\nMake Polygon great again, follow me, you’ll see,\nPolmoon is the key, the future’s ready to break free!\nA brilliant memecoin, with me, it’s guaranteed,\nPolygon and Polmoon, together, we’ll succeed!\n\n\n\n\n\n\n\nPolmoon is huge, ready for the explosion,\nOther coins? Forgotten, we are the solution!\nMemes are power, the glory is ours,\nWith me, Polygon will strike hard and make others cower!\n\nMake Polygon great again, no one can stop us now!\nPolmoon is magic, get ready for the wow.\nI’m here, Polmoon too, together we’re unbeatable,\nWe dominate the game, our victory is inevitable!\n\n\n\n\n\n\n\nRockets take off, memes fill up the space,\nPolmoon is the weapon that wins us the race!\nPolygon’s already strong, but with me it’s even better,\nPolmoon in hand, we’ll destroy it all, it’s clever!\n\nMake Polygon great again, Polmoon is our force,\nWith me, we’re winning, no regrets, no remorse.\nThe legendary memecoin, Polygon reigns forevermore,\nWith Polmoon, we win, and I’m telling you, this is the encore!"
        },
        { 
            id: 2, 
            title: "Lost my seedphrase", 
            artist: "Iggy Azalea", 
            albumArt: "/assets/music/imgMusic/Polmoon2.jpeg", 
            audioSrc: "/assets/music/polmoon2.mp3", 
            delay: 9000, 
            duration: 170, // Duration for text scrolling
            scrollingText: "I lost my seed phrase, everything collapsed,\nA moment of carelessness, and it all vanished fast.\nPolmoon just pumped, reaching unseen heights,\nBut without that key, all I do is cry.\n\nThe pump is here, but I’ll never see it,\nMy fortune, my dreams, all gone, I admit.\nPolmoon’s climbing, and I’m stuck below,\nTrapped in the shadows, nothing left to show.\n\nI had it all planned, kept everything secure,\nBut that magic phrase, I forgot for sure.\nMy Polmoon tokens are worth millions today,\nBut without my key, they’re forever away.\n\nThe pump is here,but I’ll never see it,\nMy fortune, my dreams, all gone, I admit.\nPolmoon’s climbing, and I’m stuck below,\nTrapped in the shadows, nothing left to show.\n\nI had it all planned, kept everything secure,\nBut that magic phrase, I forgot for sure.\n"
        },
        { 
            id: 3, 
            title: "Techmoon", 
            artist: "Vitalik Buterin", 
            albumArt: "/assets/music/imgMusic/Polmoon3.jpeg", 
            audioSrc: "/assets/music/Pomoon3.mp3", 
            delay: 8000, 
            duration: 230, // Duration for text scrolling
            scrollingText: "The shadows move, the market's crashing,\nIn the storm, a dark light flashing,\nPolMoon rises, the path is clear,\nIn the chaos, this is where to steer.\n\nHold your breath, everything’s fragile,\nThe world’s collapsing, silence hostile,\nPolygon reigns in the dark night,\nPolMoon climbs, forget the light.\n\n\PolMoon, in the shadows we slide,\nThe cryptos dance, it’s the edge we ride,\nThe darkness calls, this is the plan,\nBuy now before it all slips through your hand.\n\n\n\n\n\n\n\nNumbers fall, the sky turns gray,\nPolMoon shines, one last display,\nSouls seek refuge in this space,\nOne click, one move, it’s the race.\n\nThe light fades out, doubt is here,\nEach transaction feels like fear,\nPolMoon crosses through the dark,\nFate is sealed, no remorse, no spark.\n\nHold your breath, everything’s fragile,\nThe world’s collapsing, silence hostile,\nPolygon reigns in the dark night,\nPolMoon climbs, forget the light.\n\nPolMoon, in the shadows we slide,\nThe cryptos dance, it’s the edge we ride,\nThe darkness calls, this is the plan,\nBuy now before it all slips through your hand.\n\nIn the fall, PolMoon leads us through,\nOne last choice, it’s fast and true,\nSilence reigns, it’s all complete,\nBut PolMoon shines, in the infinite"
        },
        { 
            id: 4, 
            title: "Sunny moon", 
            artist: "Changpeng Zhao", 
            albumArt: "/assets/music/imgMusic/Polmoon4.jpeg", 
            audioSrc: "/assets/music/Polmoon4.mp3", 
            delay: 5000, 
            duration: 260, // Duration for text scrolling
            scrollingText: "Under the sun, chilling on my island,\nThe sound of the waves keeps me calm,\nI watch PolMoon, slowly it climbs,\nNo panic, I just go with the flow.\n\nThe wallet’s full, but my mind is light,\nPolygon shines, everything’s just right,\nI let others run after the wind,\nI just chill, knowing PolMoon will win.\n\nChill and hold, PolMoon pumps endlessly,\nOn my island, I see divine signs,\nNo stress, just let the waves roll by,\nHODL PolMoon, on Polygon it’s all aligned.\n\n\n\n\n\n\nFeet in the sand, I sip slowly,\nDays pass, PolMoon rises gently,\nNo rush, I’m at peace,\nI let the profits come without a squeeze.\n\nOthers scream, searching for answers,\nBut I know PolMoon is the good vibe enhancer,\nPolygon guides me, everything’s in control,\nI just wait, watching PolMoon take its toll.\n\nChill and hold, PolMoon pumps endlessly,\nOn my island, I see divine signs,\nNo stress, just let the waves roll by,\nHODL PolMoon, on Polygon it’s all aligned.\n\nThe sea is calm, the sky is clear,\nI see PolMoon rising, nothing to fear,\nThe wallet’s swelling, everything’s perfect,\nChill, relax, success is set.\n\nChill and hold, PolMoon pumps endlessly,\nOn my island, I see divine signs,\nNo stress, just let the waves roll by,\nHODL PolMoon, on Polygon it’s all aligned.\n\nUnder the stars, PolMoon keeps shining bright,\nI’m just chilling, nothing to regret tonight,\nOn my island, everything’s in line,\nHODL PolMoon, ready to climb."
        },
        // Add more tracks as needed
    ];

    useEffect(() => {
        const audio = audioRef.current;
        const updateTime = () => setCurrentTime(audio.currentTime);

        audio.addEventListener('timeupdate', updateTime);
        return () => {
            audio.removeEventListener('timeupdate', updateTime);
        };
    }, []);

    const handlePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
            if (currentTrack) {
                setTimeout(() => {
                    setAnimationStarted(true);
                }, currentTrack.delay);
            }
        }
        setIsPlaying(!isPlaying);
    };

    const selectTrack = (track) => {
        setCurrentTrack(track);
        setScrollDuration(track.duration);
        setAnimationStarted(false); // Reset animation
        setIsPlaying(true);
        audioRef.current.src = process.env.PUBLIC_URL + track.audioSrc;
        audioRef.current.play();
        setTimeout(() => {
            setAnimationStarted(true);
        }, track.delay);
    };

    const handleNextTrack = () => {
        if (currentTrack) {
            const currentIndex = tracks.findIndex(track => track.id === currentTrack.id);
            const nextIndex = (currentIndex + 1) % tracks.length;
            selectTrack(tracks[nextIndex]);
        }
    };

    const handlePreviousTrack = () => {
        if (currentTrack) {
            const currentIndex = tracks.findIndex(track => track.id === currentTrack.id);
            const previousIndex = (currentIndex - 1 + tracks.length) % tracks.length;
            selectTrack(tracks[previousIndex]);
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const progress = currentTrack ? (currentTime / audioRef.current.duration) * 100 : 0;
    const totalDuration = audioRef.current?.duration || 0;

    return (
        <div className="flex flex-col w-screen h-screen bg-purple-600">
            <div className="flex flex-1">
                {/* Left Section: Music List */}
                <div className="w-1/4 h-full bg-purple-700 p-4 overflow-y-auto">
                    <h2 className="text-white text-2xl mb-4">POLMOON Music List</h2>
                    <ul>
                        {tracks.map(track => (
                            <li 
                                key={track.id} 
                                className="text-white mb-2 cursor-pointer hover:text-yellow-400 hover:bg-purple-900 bg-purple-950 border-2 border-black p-2 rounded-lg"
                                onClick={() => selectTrack(track)}
                            >
                                {track.title} - {track.artist}
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Right Section: Album Art, Details, and Scrolling Text */}
                <div className="flex-1 flex flex-col items-center justify-center p-4 relative">
                    {currentTrack ? (
                        <>
                            <img 
                                src={process.env.PUBLIC_URL + currentTrack.albumArt} 
                                alt={currentTrack.title} 
                                className="w-52 mb-4 shadow-xl"
                            />
                            <h3 className="text-white text-3xl">{currentTrack.title}</h3>
                            <p className="text-white text-xl">{currentTrack.artist}</p>
                        </>
                    ) : (
                        <p className="text-white text-xl">Select a track to play</p>
                    )}
                    {/* Scrolling Text */}
                    <div className="w-full bg-purple-800 text-white overflow-hidden mt-4">
                        <div className={`ticker-wrap ${animationStarted ? 'animate' : ''}`}>
                            <div className="ticker" style={{ animationDuration: `${scrollDuration}s`, animationPlayState: isPlaying ? 'running' : 'paused' }}>
                                {currentTrack && (
                                    <div className="ticker__item" style={{ whiteSpace: 'pre-wrap', opacity: 1 }}>
                                        {currentTrack.scrollingText}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Bottom Section: Controls */}
            <div className="w-full bg-purple-800 p-4 flex justify-center  flex-col items-center">
                <div className="flex items-center justify-between w-1/2">
                    <button 
                        className="bg-yellow-400 text-black px-4 py-2 rounded-full transition duration-300 ease-in-out hover:bg-yellow-500"
                        onClick={handlePlayPause}
                    >
                        <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
                    </button>
                    <div className="flex-1 mx-4">
                        <div className="bg-gray-400 h-2 rounded shadow-lg">
                            <div className="bg-yellow-400  h-2 rounded" style={{ width: `${progress}%` }}></div>
                        </div>
                    </div>

                </div>
                <div className="flex items-center mt-2">
                    <button 
                        className="bg-yellow-400 text-black p-3 rounded-full transition duration-300 ease-in-out hover:bg-yellow-500 mx-2"
                        onClick={handlePreviousTrack}
                    >
                        <FontAwesomeIcon  icon={faBackward} />
                    </button>
                    <div className="text-white shadow-lg">
                        {formatTime(currentTime)} / {formatTime(totalDuration)}
                    </div>
                    <button 
                        className="bg-yellow-400 text-black p-3 rounded-full transition duration-300 ease-in-out hover:bg-yellow-500 mx-2"
                        onClick={handleNextTrack}
                    >
                        <FontAwesomeIcon icon={faForward} />
                    </button>
                </div>
            </div>

            {/* Audio Element */}
            <audio ref={audioRef} />

            {/* Styles for vertical scrolling text */}
            <style>
                {`
                    .ticker-wrap {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        overflow: hidden;
                        height: 10rem;
                        background-color: rgba(0, 0, 0, 0.5);
                        position: relative;
                        text-align: center;
                    }
                    .ticker {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        bottom: 0;
                        transform: translateY(100%);
                        animation-play-state: paused;
                    }
                    .ticker-wrap.animate .ticker {
                        animation: ticker linear infinite;
                    }
                    .ticker__item {
                        display: inline-block;
                        padding: 0 2rem;
                        font-size: 1rem;
                        opacity: 1;
                    }
                    @keyframes ticker {
                        0% {
                            transform: translateY(100%);
                        }
                        100% {
                            transform: translateY(-100%);
                        }
                    }
                `}
            </style>
        </div>
    );
}

export default Music;