import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

function Tokenomics() {
  const [showInfo, setShowInfo] = useState(false); // État pour gérer l'affichage de la bulle
  const [showTeamInfo, setShowTeamInfo] = useState(false); // État pour gérer l'affichage de la bulle pour l'équipe
  const [showBurnInfo, setShowBurnInfo] = useState(false); // État pour gérer l'affichage de la bulle pour le burn reverse
  const [showReflectionInfo, setShowReflectionInfo] = useState(false); 
  const toggleInfo = () => {
    setShowInfo(!showInfo); // Inverser l'état d'affichage
  };

  const toggleTeamInfo = () => {
    setShowTeamInfo(!showTeamInfo); // Inverser l'état d'affichage pour l'équipe
  };
  const toggleReflectionInfo = () => {
    setShowReflectionInfo(!showReflectionInfo);
  };
  const toggleBurnInfo = () => {
    setShowBurnInfo(!showBurnInfo); // Inverser l'état d'affichage pour le burn reverse
  };

  return (
    <div id="tokenomics" className="relative h-screen w-screen fondToken overflow-hidden flex flex-col items-center justify-center p-5 md:p-10">
    <h2 className='text-4xl md:text-6xl font-bold text-center mb-8 text-purple-600 text-outline'>
      TOKENOMICS
    </h2>
    <h3 className='text-2xl md:text-4xl text-start mb-4 text-purple-600 text-outline' data-aos="fade-left">
        LIQUIDITY POOL: <span className='text-white text-outlinee'>65%</span>
        <div className="relative inline-block"> {/* Conteneur pour le bouton et la bulle */}

        </div>
      </h3>
    
    
      
      <h3 className='text-2xl md:text-4xl text-start mb-4 text-purple-600 text-outline' data-aos="fade-right">
        AIRDROP: <span className='text-white text-outlinee'>10%</span>

      </h3>
      
      
      <h3 className='text-2xl md:text-4xl text-start mb-4 text-purple-600 text-outline' data-aos="fade-left">
        MARKETING: <span className='text-white text-outlinee'>7.5%</span>
        <div className="relative inline-block"> {/* Conteneur pour le bouton et la bulle */}
        </div>
      </h3>
      <h3 className='text-2xl md:text-4xl text-start mb-4 text-purple-600 text-outline' data-aos="fade-right">
      TEAM: <span className='text-white text-outlinee' data-aos="fade-left">7.5%</span>
      <button 
        className='text-white px-2 py-2 rounded-md ml-2' 
        onClick={toggleTeamInfo}
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </button>
    </h3>
    {showTeamInfo && (
      <div className="text-center bg-purple-600 text-white border-2 border-white text-lg p-4 rounded shadow-lg mt-1 w-full max-w-xs md:max-w-md lg:max-w-lg transform bottom-5 z-50 font-sans">
        <p>0% TGE then 6 Months Linear Vesting</p>
      </div>
    )}
    <h3 className='text-2xl md:text-4xl text-start mb-4 text-purple-600 text-outline' data-aos="fade-right">
  <br />REFLECTION: <span className='text-white text-outlinee'>2%</span>
  <button 
    className='text-white px-2 py-2 rounded-md ml-2' 
    onClick={toggleReflectionInfo}
  >
        <FontAwesomeIcon icon={faInfoCircle} />
      </button>
    </h3>
    {showReflectionInfo && (
      <div className="text-center bg-purple-600 text-white border-2 border-white text-lg p-4 rounded shadow-lg mt-1 w-full max-w-xs md:max-w-md lg:max-w-lg transform bottom-5 z-50 font-sans">
        <p className='text-blue-500 hover:text-blue-300'><a href="https://x.com/Polmoon_LFG/status/1855321410119557140" target="_blank" rel="noopener noreferrer">https://x.com/Polmoon_LFG/status/1855321410119557140</a></p>
      </div>
    )}
      
      
    </div>
  );
}

export default Tokenomics;