import React, { useState } from 'react';

function About({ setCurrentView }) { // Accept setCurrentView as a prop
  const caText = "0x181112f29410d729faEe8553654BFe0b82DD6878";
  const [showMessage, setShowMessage] = useState(false); 

  const copyToClipboard = () => {
    navigator.clipboard.writeText(caText)
      .then(() => {
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 1000);
      })
      .catch(err => {
        console.error("Erreur lors de la copie: ", err);
      });
  };

  return (
    <div id="about" className="h-auto w-screen flex flex-col items-center justify-center p-5 md:p-10 z-10 bg-purple-600">
      <h2 style={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)' }} className='text-6xl font-bold text-center text-white pt-10'>ABOUT</h2>
      <div className='flex flex-col md:flex-row items-center justify-center p-5 md:p-10'>
        <p data-aos="fade-right" className='text-2xl text-center text-white w-full md:w-1/3 mb-4 md:mb-0' style={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.9)' }}>
          The Polmoon team is convinced that Polygon is much <span className='text-yellow-400'>bigger</span> than the capitalization that the crypto market currently gives it. We believe that adding more good <span className='text-yellow-400'>memecoins</span> will help bring Polygon back to the forefront. If you're also bullish on <span className='text-yellow-400'>$POL</span>, then join us now and help us to Make Polygon Great Again and bring back more fun into its ecosystem with <span className='text-yellow-400'>$PMOON 🟣</span>
        </p>

        <img 
          src={process.env.PUBLIC_URL + '/assets/img/GM_4.gif'} 
          alt="POLMOON" 
          className='md:w-1/4' 
          style={{  transition: 'transform 0.5s ease' }}
          data-aos="fade-left" 
        />
      </div>
      <div className="flex flex-col items-center justify-center w-full px-4 py-8 md:py-12 lg:py-16 bg-purple-900">
        <h3 className='text-2xl md:text-3xl lg:text-4xl text-center text-white pt-10 mb-6 shadow-text break-words'>
          CA: 0x181112f29410d729faEe8553654BFe0b82DD6878
        </h3>
        <button 
          className='bg-purple-900 text-white px-6 py-3 rounded-md border-2 border-purple-500 hover:bg-purple-800 transition duration-300 ease-in-out mb-4'
          onClick={copyToClipboard}
        >
          COPY CA
        </button>
        {showMessage && (
          <div className="mt-4 text-green-400 text-center">CA copied</div>
        )}
      
        <div className='flex flex-col sm:flex-row items-center justify-center w-full mt-8 space-y-4 sm:space-y-0 sm:space-x-4'>
          <a 
            target="_blank" 
            rel="noopener noreferrer" 
            href='https://t.me/polmoon_portal' 
            className='bg-blue-600 text-white w-full sm:w-40 px-6 py-3 rounded-md flex items-center justify-center hover:bg-blue-700 transition duration-300 ease-in-out'
          >
            TELEGRAM
          </a>
          <a 
            target="_blank" 
            rel="noopener noreferrer" 
            href='https://x.com/Polmoon_LFG' 
            className='bg-black text-white w-full sm:w-40 px-6 py-3 rounded-md flex items-center justify-center hover:bg-gray-800 transition duration-300 ease-in-out'
          >
            X
          </a>
        </div>
      </div>
    </div>
  );
}

export default About;