import React, { useRef, useEffect, useState } from 'react';

function Generator({ onBack }) {
    const canvasRef = useRef(null);
    const [leftHandIndex, setLeftHandIndex] = useState(0);
    const [rightHandIndex, setRightHandIndex] = useState(0);
    const [twoHandsIndex, setTwoHandsIndex] = useState(0);
    const [eyesIndex, setEyesIndex] = useState(0);
    const [feetIndex, setFeetIndex] = useState(0);
    const [backgroundIndex, setBackgroundIndex] = useState(0);
    const [selectedLeftImage, setSelectedLeftImage] = useState(null);
    const [selectedRightImage, setSelectedRightImage] = useState(null);
    const [selectedTwoHandsImage, setSelectedTwoHandsImage] = useState(null);
    const [selectedEyesImage, setSelectedEyesImage] = useState(null);
    const [selectedFeetImage, setSelectedFeetImage] = useState(null);
    const [activeStep, setActiveStep] = useState(1);
    const [selectedBackgroundImage, setSelectedBackgroundImage] = useState(null);
    const [isLargeCanvas, setIsLargeCanvas] = useState(false);
    const [bulleText, setBulleText] = useState('');
    const [fontLoaded, setFontLoaded] = useState(false);
    const [bodyIndex, setBodyIndex] = useState(0);
    const [selectedBodyImage, setSelectedBodyImage] = useState(null);
    const [canvasScale, setCanvasScale] = useState(1);


    const imagesBody = Array.from({ length: 8 }, (_, index) => `${process.env.PUBLIC_URL}/assets/img/PFP/BODY/Body ${index + 1}.png`);
    const imagesLeftHand = Array.from({ length: 31 }, (_, index) => `${process.env.PUBLIC_URL}/assets/img/PFP/LEFT HAND/Main ${index + 0}.png`);
    const imagesRightHand = Array.from({ length: 30 }, (_, index) => `${process.env.PUBLIC_URL}/assets/img/PFP/RIGHT HAND/Main ${index + 0}.png`);
    const imagesTwoHands = Array.from({ length: 7 }, (_, index) => `${process.env.PUBLIC_URL}/assets/img/PFP/TWO HANDS/Two Hands ${index + 1}.png`);
    const imagesEyes = Array.from({ length: 23 }, (_, index) => `${process.env.PUBLIC_URL}/assets/img/PFP/EYES/eye ${index + 1}.png`);
    const imagesFeet = Array.from({ length: 10 }, (_, index) => `${process.env.PUBLIC_URL}/assets/img/PFP/FEET/FOOT ${index + 0}.png`);
    const imagesBackground = Array.from({ length: 28 }, (_, index) => `${process.env.PUBLIC_URL}/assets/img/PFP/BACKGROUND/BACKGROUND ${index + 1}.png`);
   
    const smallCanvasSize = { width: 600, height: 600 };
    const largeCanvasSize = { width: 1200, height: 900 };

    const bulleImage = `${process.env.PUBLIC_URL}/assets/img/PFP/BULLE/bulle.png`;

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const canvasSize = isLargeCanvas ? largeCanvasSize : smallCanvasSize;

        const resizeCanvas = () => {
            const containerWidth = canvas.parentElement.clientWidth;
            const newScale = Math.min(1, containerWidth / canvasSize.width);
            setCanvasScale(newScale);

            canvas.width = canvasSize.width * newScale;
            canvas.height = canvasSize.height * newScale;
            canvas.style.width = `${canvasSize.width * newScale}px`;
            canvas.style.height = `${canvasSize.height * newScale}px`;
        };

        resizeCanvas();
        window.addEventListener('resize', resizeCanvas);

        return () => window.removeEventListener('resize', resizeCanvas);
    }, [isLargeCanvas]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const canvasSize = isLargeCanvas ? largeCanvasSize : smallCanvasSize;

        context.setTransform(1, 0, 0, 1, 0, 0);
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.scale(canvasScale, canvasScale);

      const drawBackground = (src) => {
    return new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            const canvasSize = isLargeCanvas ? largeCanvasSize : smallCanvasSize;
            const ratio = Math.max(canvasSize.width / img.width, canvasSize.height / img.height);
            const newWidth = img.width * ratio;
            const newHeight = img.height * ratio;
            const offsetX = (canvasSize.width - newWidth) / 2;
            const offsetY = (canvasSize.height - newHeight) / 2;
            
            context.drawImage(img, offsetX, offsetY, newWidth, newHeight);
            resolve();
        };
    });
};
const drawImage = (src) => {
    return new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            if (isLargeCanvas) {
                // Calculer le ratio pour maintenir les proportions
                const ratio = Math.min(smallCanvasSize.width / img.width, smallCanvasSize.height / img.height);
                const newWidth = img.width * ratio;
                const newHeight = img.height * ratio;
                
                // Positionner l'image en bas à gauche du grand canvas
                const offsetX = 0; // Pour le positionner à gauche
                const offsetY = largeCanvasSize.height - newHeight; // Pour le positionner en bas
                
                context.drawImage(img, offsetX, offsetY, newWidth, newHeight);
            } else {
                context.drawImage(img, 0, 0, smallCanvasSize.width, smallCanvasSize.height);
            }
            resolve();
        };
    });
};

        const drawBulle = () => {
            return new Promise((resolve) => {
                const img = new Image();
                img.src = bulleImage;
                img.onload = () => {
                    const bulleWidth = canvasSize.width;
                    const bulleHeight = canvasSize.height;
                    context.drawImage(img, 0, 0, bulleWidth, bulleHeight);
                    
                    const textAreaTop = bulleHeight * 0.13;
                    const textAreaLeft = bulleWidth * 0.47;
                    const textAreaWidth = bulleWidth * 0.5;
                    const textAreaHeight = bulleHeight * 0.20;
                    const adjustedTextAreaLeft = textAreaLeft - (textAreaWidth - (bulleWidth * 0.35)) / 2;

                    const findOptimalFontSize = (text, maxWidth, maxHeight, minSize, maxSize) => {
                        let low = minSize;
                        let high = maxSize;
                        let optimalSize = low;
                        
                        const wordCount = text.split(' ').length;
                        const adjustedMaxSize = Math.min(maxSize, maxSize * (1 + (20 - Math.min(wordCount, 20)) / 20));
                        
                        while (low <= high) {
                            let mid = Math.floor((low + high) / 2);
                            context.font = `bold ${mid}px Impact`;
                            let lines = getLines(text, maxWidth);
                            let totalHeight = lines.length * (mid + 4);
        
                            if (totalHeight <= maxHeight && lines.every(line => context.measureText(line).width <= maxWidth)) {
                                optimalSize = mid;
                                low = mid + 1;
                            } else {
                                high = mid - 1;
                            }
                        }
                        return Math.min(optimalSize, adjustedMaxSize);
                    };

                    const getLines = (text, maxWidth) => {
                        let words = text.split(' ');
                        let lines = [];
                        let currentLine = words[0];
        
                        for (let i = 1; i < words.length; i++) {
                            let width = context.measureText(currentLine + " " + words[i]).width;
                            if (width < maxWidth) {
                                currentLine += " " + words[i];
                            } else {
                                lines.push(currentLine);
                                currentLine = words[i];
                            }
                        }
                        lines.push(currentLine);
                        return lines;
                    };

                    const optimalFontSize = findOptimalFontSize(bulleText, textAreaWidth, textAreaHeight, 12, 72);
                    context.font = `bold ${optimalFontSize}px Impact`;
                    context.fillStyle = 'black';
                    context.textAlign = 'center';
                    let lines = getLines(bulleText, textAreaWidth);
                    const lineHeight = optimalFontSize + 4;
                    const totalTextHeight = lines.length * lineHeight;
                    const startY = textAreaTop + (textAreaHeight - totalTextHeight) / 2 + optimalFontSize / 2;
                    lines.forEach((line, index) => {
                        let y = startY + index * lineHeight;
                        context.fillText(line, adjustedTextAreaLeft + textAreaWidth / 2, y);
                    });
                    
                    resolve();
                };
            });
        };
        const updateCanvas = async () => {
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            const canvasSize = isLargeCanvas ? largeCanvasSize : smallCanvasSize;
        
            // Effacer le canvas
            context.clearRect(0, 0, canvas.width, canvas.height);
        
            // Dessiner le fond
            if (selectedBackgroundImage) {
                await drawBackground(selectedBackgroundImage);
            } else {
                // Dessiner un fond par défaut si aucun n'est sélectionné
                context.fillStyle = '#f0f0f0';
                context.fillRect(0, 0, canvasSize.width, canvasSize.height);
            }
        
            // Dessiner le corps
            if (selectedBodyImage) await drawImage(selectedBodyImage);
        
            // Dessiner les yeux avant les mains
            if (selectedEyesImage) await drawImage(selectedEyesImage);
        
            // Dessiner la bulle si nécessaire
            if (isLargeCanvas) {
                await drawBulle();
            }
        
            // Dessiner les mains par-dessus la bulle
            if (selectedTwoHandsImage) {
                await drawImage(selectedTwoHandsImage);
            } else {
                if (selectedLeftImage) await drawImage(selectedLeftImage);
                if (selectedRightImage) await drawImage(selectedRightImage);
            }
        
            // Dessiner les pieds
            if (selectedFeetImage) await drawImage(selectedFeetImage);
        };

        updateCanvas();
    }, [selectedBodyImage, selectedLeftImage, selectedRightImage, selectedTwoHandsImage, selectedEyesImage, selectedFeetImage, selectedBackgroundImage, isLargeCanvas, bulleText, fontLoaded, canvasScale]);

    const splitTextIntoLines = (text, maxLength) => {
        const words = text.split(' ');
        const lines = [];
        let currentLine = '';
    
        words.forEach(word => {
            if ((currentLine + word).length <= maxLength) {
                currentLine += (currentLine ? ' ' : '') + word;
            } else {
                lines.push(currentLine);
                currentLine = word;
            }
        });
    
        if (currentLine) {
            lines.push(currentLine);
        }
    
        return lines.slice(0, 3);
    };

    const loadFont = () => {
        return new Promise((resolve, reject) => {
            const font = new FontFace('NomDeVotrePolice', 'url(/assets/font/font.ttf)');
            font.load().then((loadedFont) => {
                document.fonts.add(loadedFont);
                setFontLoaded(true);
                resolve();
            }).catch((error) => {
                console.error('Erreur lors du chargement de la police:', error);
                setFontLoaded(true);
                reject(error);
            });
        });
    };

    const maxImagesToShow = 7;

    const handleNext = (type) => {
        switch(type) {
            case 'body':
                if (bodyIndex < imagesBody.length - maxImagesToShow) {
                    setBodyIndex(bodyIndex + 1);
                }
                break;
            case 'twoHands':
                if (twoHandsIndex < imagesTwoHands.length - maxImagesToShow) {
                    setTwoHandsIndex(twoHandsIndex + 1);
                }
                break;
            case 'leftHand':
                if (leftHandIndex < imagesLeftHand.length - maxImagesToShow) {
                    setLeftHandIndex(leftHandIndex + 1);
                }
                break;
            case 'rightHand':
                if (rightHandIndex < imagesRightHand.length - maxImagesToShow) {
                    setRightHandIndex(rightHandIndex + 1);
                }
                break;
            case 'eyes':
                if (eyesIndex < imagesEyes.length - maxImagesToShow) {
                    setEyesIndex(eyesIndex + 1);
                }
                break;
            case 'feet':
                if (feetIndex < imagesFeet.length - maxImagesToShow) {
                    setFeetIndex(feetIndex + 1);
                }
                break;
            case 'background':
                if (backgroundIndex < imagesBackground.length - maxImagesToShow) {
                    setBackgroundIndex(backgroundIndex + 1);
                }
                break;
        }
    };

    const handlePrev = (type) => {
        switch(type) {
            case 'body':
    if (bodyIndex > 0) {
        setBodyIndex(bodyIndex - 1);
    }
    break;
            case 'leftHand':
                if (leftHandIndex > 0) {
                    setLeftHandIndex(leftHandIndex - 1);
                }
                break;
            case 'rightHand':
                if (rightHandIndex > 0) {
                    setRightHandIndex(rightHandIndex - 1);
                }
                break;
                case 'twoHands':
                    if (twoHandsIndex > 0) {
                        setTwoHandsIndex(twoHandsIndex - 1);
                    }
                    break;
            case 'eyes':
                if (eyesIndex > 0) {
                    setEyesIndex(eyesIndex - 1);
                }
                break;
            case 'feet':
                if (feetIndex > 0) {
                    setFeetIndex(feetIndex - 1);
                }
                break;
            case 'background':
                if (backgroundIndex > 0) {
                    setBackgroundIndex(backgroundIndex - 1);
                }
                break;
        }
    };

    const handleBodyImageClick = (src) => {
        setSelectedBodyImage(src);
    };
    const handleTwoHandsImageClick = (src) => {
        setSelectedTwoHandsImage(src);
        setSelectedLeftImage(null);
        setSelectedRightImage(null);
    };

    const handleLeftImageClick = (src) => {
        setSelectedLeftImage(src);
        setSelectedTwoHandsImage(null);
    };

    const handleRightImageClick = (src) => {
        setSelectedRightImage(src);
        setSelectedTwoHandsImage(null);
    };

    const handleEyesImageClick = (src) => {
        setSelectedEyesImage(src);
    };

    const handleFeetImageClick = (src) => {
        setSelectedFeetImage(src);
    };

    const handleBackgroundImageClick = (src, index) => {
        if (isLargeCanvas && (index >= 16 && index <= 21)) {
            // Ne pas permettre la sélection des arrière-plans 17 à 22 en mode large
            return;
        }
        if (!isLargeCanvas && index >= 23) {
            // Ne pas permettre la sélection des arrière-plans 23 et plus en mode petit
            return;
        }
        setSelectedBackgroundImage(src);
    };
    
    const handleRandomImage = () => {
        const randomBodyImage = imagesBody[Math.floor(Math.random() * imagesBody.length)];
        const randomEyesImage = imagesEyes[Math.floor(Math.random() * imagesEyes.length)];
        const randomFeetImage = imagesFeet[Math.floor(Math.random() * imagesFeet.length)];

        // Filtrer les arrière-plans disponibles en fonction de la taille du canvas
        const availableBackgrounds = isLargeCanvas 
        ? imagesBackground.filter((_, index) => index <= 15 || index > 21) // Inclure tout sauf 17 à 22 pour les grands formats
        : imagesBackground.slice(0, 22);// Arrière-plans 1 à 22 pour les petits formats

        const randomBackgroundImage = availableBackgrounds[Math.floor(Math.random() * availableBackgrounds.length)];

        // Randomly decide whether to use 2 hands or individual hands
        const useTwoHands = Math.random() < 0.5;

        if (useTwoHands) {
            const randomTwoHandsImage = imagesTwoHands[Math.floor(Math.random() * imagesTwoHands.length)];
            setSelectedTwoHandsImage(randomTwoHandsImage);
            setSelectedLeftImage(null);
            setSelectedRightImage(null);
        } else {
            const randomLeftImage = imagesLeftHand[Math.floor(Math.random() * imagesLeftHand.length)];
            const randomRightImage = imagesRightHand[Math.floor(Math.random() * imagesRightHand.length)];
            setSelectedTwoHandsImage(null);
            setSelectedLeftImage(randomLeftImage);
            setSelectedRightImage(randomRightImage);
        }

        setSelectedBodyImage(randomBodyImage);
        setSelectedEyesImage(randomEyesImage);
        setSelectedFeetImage(randomFeetImage);
        setSelectedBackgroundImage(randomBackgroundImage);
    };


    const handleDownload = async () => {
        const originalCanvas = canvasRef.current;
        const originalWidth = isLargeCanvas ? largeCanvasSize.width : smallCanvasSize.width;
        const originalHeight = isLargeCanvas ? largeCanvasSize.height : smallCanvasSize.height;
    
        // Create a temporary canvas with the desired size
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = originalWidth;
        tempCanvas.height = originalHeight;
        const tempContext = tempCanvas.getContext('2d');
    
        // Draw the background
        if (selectedBackgroundImage) {
            const img = new Image();
            img.src = selectedBackgroundImage;
            await new Promise((resolve) => {
                img.onload = () => {
                    tempContext.drawImage(img, 0, 0, originalWidth, originalHeight);
                    resolve();
                };
            });
        } else {
            tempContext.fillStyle = '#f0f0f0';
            tempContext.fillRect(0, 0, originalWidth, originalHeight);
        }
    
        // Draw other elements
        const drawElement = async (src, position = { x: 0, y: 0 }, scale = 1.125) => {
            if (!src) return;
            const img = new Image();
            img.src = src;
            await new Promise((resolve) => {
                img.onload = () => {
                    const newWidth = img.width * scale;
                    const newHeight = img.height * scale;
                    const offsetX = position.x;
                    const offsetY = originalHeight - newHeight + position.y; // Position at the bottom
                    tempContext.drawImage(img, offsetX, offsetY, newWidth, newHeight);
                    resolve();
                };
            });
        };
    
        await drawElement(selectedBodyImage, { x: 0, y: 0 });
        await drawElement(selectedEyesImage, { x: 0, y: 0 });
        if (selectedTwoHandsImage) {
            await drawElement(selectedTwoHandsImage, { x: 0, y: 0 });
        } else {
            await drawElement(selectedLeftImage, { x: 0, y: 0 });
            await drawElement(selectedRightImage, { x: 0, y: 0 });
        }
        await drawElement(selectedFeetImage, { x: 0, y: 0 });
    
        // Draw the bubble if necessary
        if (isLargeCanvas) {
            const bulleImg = new Image();
            bulleImg.src = bulleImage;
            await new Promise((resolve) => {
                bulleImg.onload = () => {
                    tempContext.drawImage(bulleImg, 0, 0, originalWidth, originalHeight);
                    // Draw text in the bubble
                    const bulleWidth = originalWidth;
                    const bulleHeight = originalHeight;
                    const textAreaTop = bulleHeight * 0.13;
                    const textAreaLeft = bulleWidth * 0.47;
                    const textAreaWidth = bulleWidth * 0.5;
                    const textAreaHeight = bulleHeight * 0.20;
                    const adjustedTextAreaLeft = textAreaLeft - (textAreaWidth - (bulleWidth * 0.35)) / 2;
    
                    const findOptimalFontSize = (text, maxWidth, maxHeight, minSize, maxSize) => {
                        let low = minSize;
                        let high = maxSize;
                        let optimalSize = low;
                        
                        const wordCount = text.split(' ').length;
                        const adjustedMaxSize = Math.min(maxSize, maxSize * (1 + (20 - Math.min(wordCount, 20)) / 20));
                        
                        while (low <= high) {
                            let mid = Math.floor((low + high) / 2);
                            tempContext.font = `bold ${mid}px Impact`;
                            let lines = getLines(text, maxWidth);
                            let totalHeight = lines.length * (mid + 4);
    
                            if (totalHeight <= maxHeight && lines.every(line => tempContext.measureText(line).width <= maxWidth)) {
                                optimalSize = mid;
                                low = mid + 1;
                            } else {
                                high = mid - 1;
                            }
                        }
                        return Math.min(optimalSize, adjustedMaxSize);
                    };
    
                    const getLines = (text, maxWidth) => {
                        let words = text.split(' ');
                        let lines = [];
                        let currentLine = words[0];
    
                        for (let i = 1; i < words.length; i++) {
                            let width = tempContext.measureText(currentLine + " " + words[i]).width;
                            if (width < maxWidth) {
                                currentLine += " " + words[i];
                            } else {
                                lines.push(currentLine);
                                currentLine = words[i];
                            }
                        }
                        lines.push(currentLine);
                        return lines;
                    };
    
                    const optimalFontSize = findOptimalFontSize(bulleText, textAreaWidth, textAreaHeight, 12, 72);
                    tempContext.font = `bold ${optimalFontSize}px Impact`;
                    tempContext.fillStyle = 'black';
                    tempContext.textAlign = 'center';
                    let lines = getLines(bulleText, textAreaWidth);
                    const lineHeight = optimalFontSize + 4;
                    const totalTextHeight = lines.length * lineHeight;
                    const startY = textAreaTop + (textAreaHeight - totalTextHeight) / 2 + optimalFontSize / 2;
                    lines.forEach((line, index) => {
                        let y = startY + index * lineHeight;
                        tempContext.fillText(line, adjustedTextAreaLeft + textAreaWidth / 2, y);
                    });
    
                    resolve();
                };
            });
        }
    
        // Download the image
        const link = document.createElement('a');
        link.download = 'Polmoon_LFG.png';
        link.href = tempCanvas.toDataURL('image/png', 1.0); // Maximum quality
        link.click();
    };
    const toggleCanvasSize = () => {
        if (isLargeCanvas) {
            // Si on passe du grand format au petit format
            if (selectedBackgroundImage && imagesBackground.indexOf(selectedBackgroundImage) >= 23) {
                // Si l'arrière-plan actuel est un grand format, le réinitialiser
                setSelectedBackgroundImage(imagesBackground[0]); // Choisissez un arrière-plan par défaut pour le petit format
            }
        } else {
            // Si on passe du petit format au grand format
            if (selectedBackgroundImage && imagesBackground.indexOf(selectedBackgroundImage) >= 17 && imagesBackground.indexOf(selectedBackgroundImage) <= 22) {
                // Si l'arrière-plan actuel est restreint pour le grand format, le réinitialiser
                setSelectedBackgroundImage(imagesBackground[0]);
            }
        }
        setIsLargeCanvas(!isLargeCanvas);
    };
    return (
        <div className="text-center min-h-screen w-full bg-purple-600 flex flex-col justify-center items-center p-2 md:p-5 overflow-x-hidden ">
            <div className='flex flex-col md:flex-row justify-center items-center w-full max-w-6xl '>
                <div className='w-full md:w-1/2 mb-4 md:mb-0 m-4'>
                    <canvas 
                        ref={canvasRef} 
                        style={{
                            width: '100%',
                            height: 'auto',
                            maxWidth: isLargeCanvas ? `${largeCanvasSize.width}px` : `${smallCanvasSize.width}px`,
                            maxHeight: isLargeCanvas ? `${largeCanvasSize.height}px` : `${smallCanvasSize.height}px`,
                        }}
                        className="border border-black rounded"
                    />
                </div>
                <div className='w-full md:w-2/3 flex flex-col'>
                <div className="mb-4 flex justify-center">
                    <button 
                        onClick={() => setActiveStep(1)}
                        className={`p-2 m-2 rounded ${activeStep === 1 ? 'bg-purple-900' : 'bg-purple-500'} text-white`}
                    >
                        Step 1: Choose Parts
                    </button>
                    <button 
                        onClick={() => setActiveStep(2)}
                        className={`p-2 m-2 rounded ${activeStep === 2 ? 'bg-purple-900' : 'bg-purple-500'} text-white`}
                    >
                        Step 2: Finalize
                    </button>
                </div>
                {activeStep === 1 && (
                    <>
                        <div className="h-[600px] overflow-y-auto pr-4 bg-purple-900 rounded border border-white custom-scrollbar">

                            <div className='w-full'>
                                <h2 className="text-white text-xl font-bold mt-4 mb-2 text-outlineee">Body</h2>
                                <div className='flex flex-row justify-center items-center'>
                                    <button 
                                        onClick={() => handlePrev('body')} 
                                        disabled={bodyIndex === 0} 
                                        className={`p-2 m-2 rounded ${bodyIndex === 0 ? 'bg-gray-300 cursor-not-allowed transform scale-110' : 'bg-purple-500 hover:bg-purple-900'}`}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                        </svg>
                                    </button>
                                    {imagesBody.slice(bodyIndex, bodyIndex + maxImagesToShow).map((src, index) => (
                                        <img 
                                            key={index} 
                                            className='w-1/5 md:w-1/6 lg:w-16 border border-white m-1 cursor-pointer bg-purple-900 rounded'
                                            src={src} 
                                            alt="img" 
                                            onClick={() => handleBodyImageClick(src)}
                                        />
                                    ))}
                                    <button 
                                        onClick={() => handleNext('body')} 
                                        disabled={bodyIndex >= imagesBody.length - maxImagesToShow} 
                                        className={`p-2 m-2 rounded ${bodyIndex >= imagesBody.length - maxImagesToShow ? 'bg-gray-300 cursor-not-allowed transform scale-110' : 'bg-purple-500 hover:bg-purple-900'}`}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className='w-full'>
                                <h2 className="text-white text-xl font-bold mt-4 mb-2 text-outlineee">Left Hand</h2>
                                <div className='flex flex-row justify-center items-center'>
                                    <button 
                                        onClick={() => handlePrev('leftHand')} 
                                        disabled={leftHandIndex === 0} 
                                        className={`p-2 m-2 rounded ${leftHandIndex === 0 ? 'bg-gray-300 cursor-not-allowed transform scale-110' : 'bg-purple-500 hover:bg-purple-900'}`}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                        </svg>
                                    </button>
                                    {imagesLeftHand.slice(leftHandIndex, leftHandIndex + maxImagesToShow).map((src, index) => (
                                        <img 
                                            key={index} 
                                            className='w-1/5 md:w-1/6 lg:w-16 border border-white m-1 cursor-pointer bg-purple-900 rounded'
                                            src={src} 
                                            alt="img" 
                                            onClick={() => handleLeftImageClick(src)}
                                        />
                                    ))}
                                    <button 
                                        onClick={() => handleNext('leftHand')} 
                                        disabled={leftHandIndex >= imagesLeftHand.length - maxImagesToShow} 
                                        className={`p-2 m-2 rounded ${leftHandIndex >= imagesLeftHand.length - maxImagesToShow ? 'bg-gray-300 cursor-not-allowed transform scale-110' : 'bg-purple-500 hover:bg-purple-900'}`}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            {/* Right Hand */}
                            <div className='w-full'>
                                <h2 className="text-white text-xl font-bold mt-4 mb-2 text-outlineee">Right Hand</h2>
                                <div className='flex flex-row justify-center items-center'>
                                    <button 
                                        onClick={() => handlePrev('rightHand')} 
                                        disabled={rightHandIndex === 0} 
                                        className={`p-2 m-2 rounded ${rightHandIndex === 0 ? 'bg-gray-300 cursor-not-allowed transform scale-110' : 'bg-purple-500 hover:bg-purple-900'}`}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                        </svg>
                                    </button>
                                    {imagesRightHand.slice(rightHandIndex, rightHandIndex + maxImagesToShow).map((src, index) => (
                                        <img 
                                            key={index} 
                                            className='w-1/5 md:w-1/6 lg:w-16 border border-white m-1 cursor-pointer bg-purple-900 rounded'
                                            src={src} 
                                            alt="img" 
                                            onClick={() => handleRightImageClick(src)}
                                        />
                                    ))}
                                    <button 
                                        onClick={() => handleNext('rightHand')} 
                                        disabled={rightHandIndex >= imagesRightHand.length - maxImagesToShow} 
                                        className={`p-2 m-2 rounded ${rightHandIndex >= imagesRightHand.length - maxImagesToShow ? 'bg-gray-300 cursor-not-allowed transform scale-110' : 'bg-purple-500 hover:bg-purple-900'}`}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                             {/* Two Hands */}
                            <div className='w-full'>
                                <h2 className="text-white text-xl font-bold mt-4 mb-2 text-outlineee">Two Hands</h2>
                                <div className='flex flex-row justify-center items-center'>
                                    <button 
                                        onClick={() => handlePrev('twoHands')} 
                                        disabled={twoHandsIndex === 0} 
                                        className={`p-2 m-2 rounded ${twoHandsIndex === 0 ? 'bg-gray-300 cursor-not-allowed transform scale-110' : 'bg-purple-500 hover:bg-purple-900'}`}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                        </svg>
                                    </button>
                                    {imagesTwoHands.slice(twoHandsIndex, twoHandsIndex + maxImagesToShow).map((src, index) => (
                                        <img 
                                            key={index} 
                                            className='w-1/5 md:w-1/6 lg:w-16 border border-white m-1 cursor-pointer bg-purple-900 rounded'
                                            src={src} 
                                            alt="img" 
                                            onClick={() => handleTwoHandsImageClick(src)}
                                        />
                                    ))}
                                    <button 
                                        onClick={() => handleNext('twoHands')} 
                                        disabled={twoHandsIndex >= imagesTwoHands.length - maxImagesToShow} 
                                        className={`p-2 m-2 rounded ${twoHandsIndex >= imagesTwoHands.length - maxImagesToShow ? 'bg-gray-300 cursor-not-allowed transform scale-110' : 'bg-purple-500 hover:bg-purple-900'}`}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                         
                            {/* Eyes */}
                            <div className='w-full'>
                                <h2 className="text-white text-xl font-bold mt-4 mb-2 text-outlineee">Eyes</h2>
                                <div className='flex flex-row justify-center items-center'>
                                    <button 
                                        onClick={() => handlePrev('eyes')} 
                                        disabled={eyesIndex === 0} 
                                        className={`p-2 m-2 rounded ${eyesIndex === 0 ? 'bg-gray-300 cursor-not-allowed transform scale-110' : 'bg-purple-500 hover:bg-purple-900'}`}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                        </svg>
                                    </button>
                                    {imagesEyes.slice(eyesIndex, eyesIndex + maxImagesToShow).map((src, index) => (
                                        <img 
                                            key={index} 
                                            className='w-1/5 md:w-1/6 lg:w-16 border border-white m-1 cursor-pointer bg-purple-900 rounded'
                                            src={src} 
                                            alt="img" 
                                            onClick={() => handleEyesImageClick(src)}
                                        />
                                    ))}
                                    <button 
                                        onClick={() => handleNext('eyes')} 
                                        disabled={eyesIndex >= imagesEyes.length - maxImagesToShow} 
                                        className={`p-2 m-2 rounded ${eyesIndex >= imagesEyes.length - maxImagesToShow ? 'bg-gray-300 cursor-not-allowed transform scale-110' : 'bg-purple-500 hover:bg-purple-900'}`}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                               {/* Left Hand */}
                            
                            {/* Feet */}
                            <div className='w-full'>
                                <h2 className="text-white text-xl font-bold mt-4 mb-2 text-outlineee">Feet</h2>
                                <div className='flex flex-row justify-center items-center'>
                                    <button 
                                        onClick={() => handlePrev('feet')} 
                                        disabled={feetIndex === 0} 
                                        className={`p-2 m-2 rounded ${feetIndex === 0 ? 'bg-gray-300 cursor-not-allowed transform scale-110' : 'bg-purple-500 hover:bg-purple-900'}`}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                        </svg>
                                    </button>
                                    {imagesFeet.slice(feetIndex, feetIndex + maxImagesToShow).map((src, index) => (
                                        <img 
                                            key={index} 
                                            className='w-1/5 md:w-1/6 lg:w-16 border border-white m-1 cursor-pointer bg-purple-900 rounded'
                                            src={src} 
                                            alt="img" 
                                            onClick={() => handleFeetImageClick(src)}
                                        />
                                    ))}
                                    <button 
                                        onClick={() => handleNext('feet')} 
                                        disabled={feetIndex >= imagesFeet.length - maxImagesToShow} 
                                        className={`p-2 m-2 rounded ${feetIndex >= imagesFeet.length - maxImagesToShow ? 'bg-gray-300 cursor-not-allowed transform scale-110' : 'bg-purple-500 hover:bg-purple-900'}`}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            {/* Background */}
                            <div className='w-full'>
    <h2 className="text-white text-xl font-bold mt-4 mb-2 text-outlineee">Background</h2>
    <div className='flex flex-row justify-center items-center'>
        <button 
            onClick={() => handlePrev('background')} 
            disabled={backgroundIndex === 0} 
            className={`p-2 m-2 rounded ${backgroundIndex === 0 ? 'bg-gray-300 cursor-not-allowed transform scale-110' : 'bg-purple-500 hover:bg-purple-900'}`}
        >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
        </button>
        {imagesBackground.slice(backgroundIndex, backgroundIndex + maxImagesToShow).map((src, index) => {
    const globalIndex = backgroundIndex + index;
    const isDisabled = (isLargeCanvas && (globalIndex >= 16 && globalIndex <= 21)) || (!isLargeCanvas && globalIndex >= 22);
    const tooltipText = isLargeCanvas 
        ? "Use 'Add Text' for this background"
        : "Use without 'Add Text' for this background";

    return (
        <img 
            key={index} 
            className={`w-1/5 md:w-1/6 lg:w-16 border border-white m-1 bg-purple-900 rounded`}
            style={{
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                opacity: isDisabled ? 0.5 : 1
            }}
            src={src} 
            alt="img" 
            onClick={() => !isDisabled && handleBackgroundImageClick(src, globalIndex)}
            title={isDisabled ? tooltipText : ''}
        />
    );
})}
        <button 
            onClick={() => handleNext('background')} 
            disabled={backgroundIndex >= imagesBackground.length - maxImagesToShow} 
            className={`p-2 m-2 rounded ${backgroundIndex >= imagesBackground.length - maxImagesToShow ? 'bg-gray-300 cursor-not-allowed transform scale-110' : 'bg-purple-500 hover:bg-purple-900'}`}
        >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
        </button>
    </div>
</div>
                        </div>
                        <div className="mt-4 flex justify-center">
                    <button 
                        onClick={handleRandomImage} 
                        className="p-2 m-2 bg-purple-900 text-white rounded w-1/2 border border-white"
                    >
                        Random
                    </button>
                </div>
                    </>
                )}
                {activeStep === 2 && (
                    <>
                        <div className='mt-4 bg-purple-900 rounded border border-white p-4'>
                            <button 
                                onClick={toggleCanvasSize} 
                                className={`p-2 m-2 rounded ${isLargeCanvas ? 'bg-green-500' : 'bg-red-500'} text-white w-1/3`}
                            >
                                {isLargeCanvas ? 'ADD TEXT ON' : 'ADD TEXT OFF'}
                            </button>
                            {isLargeCanvas && (
                                <input 
                                    type="text" 
                                    value={bulleText}
                                    onChange={(e) => setBulleText(e.target.value)}
                                    placeholder="Your text here"
                                    className="p-2 m-2 w-2/3 border rounded"
                                />
                            )}
                        </div>
                        <div className="mt-4 flex justify-center">
                        <button 
                        onClick={handleRandomImage} 
                        className="p-2 m-2 bg-purple-900 text-white rounded w-1/2 border border-white"
                    >
                        Random
                    </button>
                            <button 
                                onClick={handleDownload} 
                                className="p-2 m-2 bg-purple-900 text-white rounded w-1/2 border border-white"
                            >
                                Download
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    </div>
);
}       

export default Generator;