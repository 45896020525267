import React, { useEffect, useState, useRef } from 'react';
import Home from './components/Home';
import About from './components/About';
import Tokenomics from './components/Tokenomics';
import Footer from './components/Footer';
import Nav from './components/Nav';
import Generator from './components/Generator';
import Music from './components/Music'; // Import the Music component

const Rocket = ({ position, duration, angle, size, onAnimationEnd }) => {
  const rocketRef = useRef(null);

  useEffect(() => {
    const rocket = rocketRef.current;
    const endX = window.innerWidth * (1 + Math.random() * 0.5);
    const endY = window.innerHeight * (1 + Math.random() * 0.5);

    const animation = rocket.animate([
      { transform: `translate(0, 0)` },
      { transform: `translate(${endX * Math.cos(angle * Math.PI / 180)}px, -${endY * Math.sin(angle * Math.PI / 180)}px)` }
    ], {
      duration: duration,
      easing: 'linear',
      fill: 'forwards'
    });

    animation.onfinish = onAnimationEnd;
  }, []);

  return (
    <img 
      ref={rocketRef}
      src={process.env.PUBLIC_URL + '/assets/img/fusee3.gif'} 
      alt="Rocket" 
      className="fixed -z-10" 
      style={{
        ...position,
        width: `${size}px`,
        height: 'auto',
      }} 
    />
  );
};

const App = () => {
  const [rockets, setRockets] = useState([]);
  const [currentView, setCurrentView] = useState('home'); // New state to manage views
  const maxSize = 100;
  const minSize = maxSize / 10;
  const maxDuration = 30000;
  const minDuration = 3000;

  const addRocket = () => {
    const isBottom = Math.random() < 0.5;
    let position;
    
    if (isBottom) {
      position = {
        left: `${Math.random() * 100}%`,
        bottom: '0'
      };
    } else {
      position = {
        left: '0',
        bottom: `${Math.random() * 100}%`
      };
    }

    const randomAngle = Math.random() * 30 + 30;
    const randomSize = Math.random() * (maxSize - minSize) + minSize;
    
    const sizeRatio = (randomSize - minSize) / (maxSize - minSize);
    const duration = maxDuration - Math.pow(sizeRatio, 3) * (maxDuration - minDuration);
    
    const newRocket = { 
      id: Date.now(), 
      position, 
      duration: duration, 
      angle: randomAngle,
      size: randomSize
    };
    
    setRockets(prevRockets => [...prevRockets, newRocket]);
  };

  const removeRocket = (id) => {
    setRockets(prevRockets => prevRockets.filter(rocket => rocket.id !== id));
  };

  useEffect(() => {
    const interval = setInterval(addRocket, 2000);
    return () => clearInterval(interval);
  }, []);

  const handleBackClick = () => {
    setCurrentView('home');
  };

  return (
    <div className="App">
      {currentView === 'home' && (
        <>
          <button 
            className='absolute top-0 right-0 hidden md:block bg-yellow-400 text-black px-4 py-4 rounded-md transition duration-300 ease-in-out hover:bg-yellow-500 mx-6 my-3 z-50' 
            onClick={() => setCurrentView('generator')}
          >
            Generator
          </button>
          <Nav />
          <Home />
          {rockets.map((rocket) => (
            <Rocket 
              key={rocket.id} 
              {...rocket} 
              onAnimationEnd={() => removeRocket(rocket.id)}
            />
          ))}
          <div className='flex flex-col justify-center items-center h-auto pt-10 pb-10 bg-purple-600'>
            <h1 className='text-white text-6xl font-bold text-center'>Memes factory</h1>
            <button 
          className='z-50  w-32 px-6 py-3 rounded-md bg-yellow-400  hover:bg-yellow-500 mt-10 px-6 py-6  transition duration-300 ease-in-out mb-4'
          onClick={() => setCurrentView('music')}
        >
          Music
        </button>
            <button 
              className='bg-yellow-400 w-32 text-black px-6 py-6 rounded-md transition duration-300 ease-in-out hover:bg-yellow-500 mt-10' 
              onClick={() => setCurrentView('generator')}
            >
              Generator
            </button>
          </div>
          <About setCurrentView={setCurrentView} /> {/* Pass setCurrentView to About */}
          <Tokenomics />
          <Footer />
        </>
      )}
      {currentView === 'generator' && (
        <div className="flex justify-center items-center">
          <Generator onBack={handleBackClick} />
          <button 
            className="mt-4 mr-4 fixed top-0 right-0 bg-purple-900 text-white px-4 py-2 rounded-md transition duration-300 ease-in-out hover:bg-purple-700" 
            onClick={handleBackClick}
          >
            Back
          </button>
        </div>
      )}
      {currentView === 'music' && (
        <div className="flex justify-center items-center">
          <Music setCurrentView={setCurrentView} /> {/* Pass setCurrentView to Music */}
          <button 
            className="mt-4 mr-4 fixed top-0 right-0 bg-purple-900 text-white px-4 py-2 rounded-md transition duration-300 ease-in-out hover:bg-purple-700" 
            onClick={handleBackClick}
          >
            Back
          </button>
        </div>
      )}
    </div>
  );
}

export default App;